import React, { useState } from 'react';
import { ChevronDown, Search, ShoppingCart, Sun, Bell, Settings, LogOut } from 'lucide-react';

// Layout Component
const MainLayout = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [isFrontendOpen, setIsFrontendOpen] = useState(true);

    return (
        <div className="flex h-screen bg-slate-50">
            {/* Sidebar */}
            <div className={`bg-white w-64 border-r flex-shrink-0 ${isSidebarOpen ? '' : 'hidden'}`}>
                {/* Logo */}
                <div className="px-6 py-4 border-b">
                    <div className="flex items-center space-x-2">
                        <div className="w-8 h-8 bg-blue-600 rounded-lg flex items-center justify-center">
                            <svg className="w-5 h-5 text-white" viewBox="0 0 24 24" fill="none">
                                <path d="M12 2L2 7L12 12L22 7L12 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <span className="text-xl font-semibold">Modernize</span>
                    </div>
                </div>

                {/* Navigation */}
                <nav className="p-4 space-y-4">
                    <div className="space-y-2">
                        <div className="text-sm font-medium text-gray-500 px-2">HOME</div>
                        <div className="space-y-1">
                            <NavItem icon="🎯" label="Modern" badge="New" />
                            <NavItem icon="🛒" label="eCommerce" />
                        </div>
                    </div>

                    {/* Frontend Pages Section */}
                    <div className="space-y-1">
                        <button
                            onClick={() => setIsFrontendOpen(!isFrontendOpen)}
                            className="w-full flex items-center justify-between px-2 py-1.5 rounded-lg hover:bg-blue-50 cursor-pointer group"
                        >
                            <div className="flex items-center space-x-2">
                                <div className="flex items-center justify-center w-5 h-5 text-gray-600">
                                    <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13 2H6C5.46957 2 4.96086 2.21071 4.58579 2.58579C4.21071 2.96086 4 3.46957 4 4V20C4 20.5304 4.21071 21.0391 4.58579 21.4142C4.96086 21.7893 5.46957 22 6 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V9L13 2Z" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <span className="text-sm font-medium text-gray-700">Frontend pages</span>
                            </div>
                            <ChevronDown
                                className={`w-4 h-4 text-gray-500 transition-transform duration-200 ${isFrontendOpen ? 'transform rotate-180' : ''
                                    }`}
                            />
                        </button>

                        <div className={`pl-8 space-y-1 overflow-hidden transition-all duration-200 ${isFrontendOpen ? 'max-h-64 opacity-100' : 'max-h-0 opacity-0'
                            }`}>
                            <SubNavItem label="Homepage" />
                            <SubNavItem label="About Us" />
                            <SubNavItem label="Blog" />
                            <SubNavItem label="Blog Details" />
                            <SubNavItem label="Contact" />
                            <SubNavItem label="Portfolio" />
                            <SubNavItem label="Pricing" />
                        </div>
                    </div>

                    {/* Apps Section */}
                    <div className="space-y-2">
                        <div className="text-sm font-medium text-gray-500 px-2">APPS</div>
                        <div className="space-y-1">
                            <NavItem icon="👥" label="Contacts" badge="2" isActive />
                            <NavItem icon="📝" label="Blog" />
                        </div>
                    </div>

                    {/* User Profile */}
                    <div className="absolute bottom-4 left-4 right-4">
                        <div className="flex items-center p-2">
                            <div className="flex items-center space-x-3">
                                <img
                                    src="/api/placeholder/40/40"
                                    alt="User"
                                    className="w-8 h-8 rounded-lg"
                                />
                                <div className="flex-1">
                                    <div className="text-sm font-medium text-gray-900">Mathew</div>
                                    <div className="text-xs text-gray-500">Designer</div>

                                </div>
                                <button className="ml-auto p-1.5 rounded-lg hover:bg-gray-100">
                                    <LogOut className="w-4 h-4 text-gray-500" />
                                </button>
                            </div>

                        </div>
                    </div>
                </nav>
            </div>

            {/* Main Content */}
            <div className="flex-1 flex flex-col">
                {/* Top Bar */}
                <header className="bg-white border-b h-16 flex items-center justify-between px-6">
                    <div className="flex items-center space-x-4">
                        <button
                            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                            className="text-gray-500 hover:text-gray-700"
                        >
                            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                            </svg>
                        </button>
                        <div className="flex items-center space-x-4">
                            <button className="text-gray-700 hover:text-gray-900">Apps</button>
                            <button className="text-gray-500 hover:text-gray-700">Chat</button>
                            <button className="text-gray-500 hover:text-gray-700">Calendar</button>
                            <button className="text-gray-500 hover:text-gray-700">Email</button>
                        </div>
                    </div>

                    <div className="flex items-center space-x-4">
                        <button className="p-2 text-gray-500 hover:text-gray-700">
                            <img src="/api/placeholder/24/24" alt="Language" className="w-6 h-6 rounded-full" />
                        </button>
                        <button className="p-2 text-gray-500 hover:text-gray-700 relative">
                            <ShoppingCart className="w-5 h-5" />
                            <span className="absolute top-0 right-0 w-4 h-4 bg-orange-500 text-white text-xs rounded-full flex items-center justify-center">6</span>
                        </button>
                        <button className="p-2 text-gray-500 hover:text-gray-700">
                            <Sun className="w-5 h-5" />
                        </button>
                        <button className="p-2 text-gray-500 hover:text-gray-700 relative">
                            <Bell className="w-5 h-5" />
                            <span className="absolute top-0 right-0 w-2 h-2 bg-blue-500 rounded-full"></span>
                        </button>
                        <button className="p-2 text-gray-500 hover:text-gray-700">
                            <Settings className="w-5 h-5" />
                        </button>
                        <button className="p-2">
                            <img src="/api/placeholder/32/32" alt="Profile" className="w-8 h-8 rounded-full" />
                        </button>
                    </div>
                </header>

                {/* Main Content Area */}
                {/*<main className="flex-1 overflow-auto p-6">
          {children}
        </main>*/}
            </div>
        </div>
    );
};

// Helper Components
const NavItem = ({ icon, label, badge, isActive }) => (
    <div className={`flex items-center justify-between px-2 py-1 rounded-lg cursor-pointer ${isActive ? 'bg-blue-500 text-white' : 'hover:bg-blue-50 text-gray-700'
        }`}>
        <div className="flex items-center space-x-2">
            <span>{icon}</span>
            <span className="text-sm font-medium">{label}</span>
        </div>
        {badge && (
            <span className={`text-xs px-2 py-0.5 rounded-full ${isActive ? 'bg-blue-400' : 'bg-blue-100 text-blue-600'
                }`}>
                {badge}
            </span>
        )}
    </div>
);

const SubNavItem = ({ label }) => (
    <div className="text-sm text-gray-600 hover:text-blue-600 cursor-pointer py-1">
        {label}
    </div>
);

export default MainLayout;