import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React, { Suspense, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import Login from '../pages/login/login';
import MainLayout from '../components/main_layout';


function App() {
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={<Login />}
        />
        <Route path='/admin' element={<MainLayout />} >

        </Route>

      </Routes>
    </Router>
  );
}

export default App;
